<template>
  <header v-if="!is.initial" class="entity-detailheader">
    <b-row align-v="end">
      <b-col lg>
        <slot :entity="entity" :label="label" :definition="definition"/>
      </b-col>

      <b-col lg="auto">
        <dl class="detailheader-list justify-content-between">
          <template v-if="securityLevel">
            <dt>{{ $t('components.entity.detailheader.securitylevel.label') }}</dt>
            <dd><b-badge :variant="`securitylevel${entity.securityLevel}`">{{ securityLevel }}</b-badge></dd>
          </template>
        </dl>
      </b-col>
    </b-row>

    <div class="detailheader-separator"></div>
  </header>
</template>

<script>
import { entityLabelFormatter } from '@/assets/js/helper/entity'

export default {
  name: 'EntityDetailHeader',
  components: {
    // EntityState
  },
  props: {
    entityKey: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    definition () {
      return this.$store.getters[`${this.entityKey}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.entityKey}/getEntityUnwrapped`](this.entityId)
    },
    label () {
      return entityLabelFormatter(this.definition, this.entity)
    },
    securityLevel () {
      return ((((this.definition.$properties.securityLevel || {}).translations || {})[this.$i18n.locale] || {}).selectValues || {})[this.entity.securityLevel]
    }
  }
}
</script>

<style lang="scss">
$entity-header-bottom-gap: $spacer * 1.4 !default;

$entity-header-separator-y-gap: $spacer * 0.8 !default;
$entity-header-separator-height: 5px !default;
$entity-header-separator-bg: $dark !default;

.entity-detailheader {
  margin-bottom: $entity-header-bottom-gap;

  .detailheader-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    > dt {
      margin-right: $spacer;
    }

    > dd {
      margin: 0;

      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin: 0;
      }
    }
  }

  .detailheader-separator {
    margin-top: $entity-header-separator-y-gap;
    height: $entity-header-separator-height;
    background-color: $entity-header-separator-bg;
  }
}
</style>
