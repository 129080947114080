<template>
  <header class="entity-entryheader">
    <b-row align-v="end">
      <b-col lg>
        <h1 v-if="title">{{ title }}</h1>
      </b-col>

      <b-col lg="auto" class="text-right">
        <strong>{{ $t('components.entity.entryheader.total.items.label') }}</strong>&nbsp;
        <b-badge variant="light">
          <loader v-if="is.initial" inline/>
          <template v-else>{{ statistics.total.items }}</template>
        </b-badge>
      </b-col>
    </b-row>

    <div class="entryheader-separator"></div>
  </header>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  name: 'EntityEntryHeader',
  components: {
    Loader
  },
  props: {
    entityKey: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    statistics () {
      return this.$store.getters[`${this.entityKey}/statistics`]
    }
  }
}
</script>

<style lang="scss">
$entity-header-bottom-gap: $spacer * 1.4 !default;

$entity-header-separator-y-gap: $spacer * 0.8 !default;
$entity-header-separator-height: 5px !default;
$entity-header-separator-bg: $dark !default;

.entity-entryheader {
  margin-bottom: $entity-header-bottom-gap;

  h1 {
    margin: 0;
  }

  .entryheader-separator {
    margin-top: $entity-header-separator-y-gap;
    height: $entity-header-separator-height;
    background-color: $entity-header-separator-bg;
  }
}
</style>
